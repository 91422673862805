exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-form-success-tsx": () => import("./../../../src/pages/form-success.tsx" /* webpackChunkName: "component---src-pages-form-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-paginated-tsx": () => import("./../../../src/templates/BlogPaginated.tsx" /* webpackChunkName: "component---src-templates-blog-paginated-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2019-09-17-building-machineservant-part-1-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2019-09-17-building-machineservant-part-1/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2019-09-17-building-machineservant-part-1-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2019-09-23-publishing-posts-with-remark-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2019-09-23-publishing-posts-with-remark/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2019-09-23-publishing-posts-with-remark-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-10-08-gatsby-weatherwidget-io-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2020-10-08-gatsby-weatherwidget-io/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-10-08-gatsby-weatherwidget-io-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-11-02-storybook-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2020-11-02-storybook/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-11-02-storybook-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-11-05-bit-dev-storybook-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2020-11-05-bit-dev-storybook/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-11-05-bit-dev-storybook-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-11-16-purgecss-gatsby-and-tailwind-in-dependencies-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2020-11-16-purgecss-gatsby-and-tailwind-in-dependencies/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2020-11-16-purgecss-gatsby-and-tailwind-in-dependencies-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2021-01-19-what-about-security-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2021-01-19-what-about-security/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2021-01-19-what-about-security-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2021-02-19-why-and-how-i-started-machineservant-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2021-02-19-why-and-how-i-started-machineservant/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2021-02-19-why-and-how-i-started-machineservant-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2022-11-11-remix-musings-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2022-11-11-remix-musings/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2022-11-11-remix-musings-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2022-11-12-remix-optimistic-with-ui-multiple-destinations-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2022-11-12-remix-optimistic-with-ui-multiple-destinations/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2022-11-12-remix-optimistic-with-ui-multiple-destinations-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-05-chat-gpt-is-crazy-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-05-chat-gpt-is-crazy/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-05-chat-gpt-is-crazy-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-06-web-development-trends-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-06-web-development-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-06-web-development-trends-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-07-cloud-services-model-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-07-cloud-services-model/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-07-cloud-services-model-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-13-what-is-python-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-13-what-is-python/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-13-what-is-python-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-15-angular-vs-react-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-15-angular-vs-react/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-15-angular-vs-react-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-16-web-application-security-xss-and-injection-attacks-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-16-web-application-security-xss-and-injection-attacks/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-16-web-application-security-xss-and-injection-attacks-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-21-why-you-should-be-a-front-end-developer-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-21-why-you-should-be-a-front-end-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-21-why-you-should-be-a-front-end-developer-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-22-front-end-vs-backend-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-22-front-end-vs-backend/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-22-front-end-vs-backend-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-25-storybook-vs-bit-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-25-storybook-vs-bit/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-25-storybook-vs-bit-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-26-remix-react-framework-2023-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-01-26-remix-react-framework-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-01-26-remix-react-framework-2023-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-03-using-graphql-with-remix-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-03-using-graphql-with-remix/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-03-using-graphql-with-remix-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-06-should-i-use-remix-and-graphql-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-06-should-i-use-remix-and-graphql/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-06-should-i-use-remix-and-graphql-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-08-web-application-development-guide-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-08-web-application-development-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-08-web-application-development-guide-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-09-mobile-application-technologies-for-startups-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-09-mobile-application-technologies-for-startups/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-09-mobile-application-technologies-for-startups-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-10-react-native-apps-overview-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-10-react-native-apps-overview/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-10-react-native-apps-overview-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-11-progressive-web-application-development-overview-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-11-progressive-web-application-development-overview/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-11-progressive-web-application-development-overview-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-13-how-to-become-a-web-developer-from-scratch-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-13-how-to-become-a-web-developer-from-scratch/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-13-how-to-become-a-web-developer-from-scratch-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-17-gatsby-js-overview-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-17-gatsby-js-overview/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-17-gatsby-js-overview-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-18-p-1-gatsby-js-blog-starting-the-project-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-18-p1-gatsby-js-blog-starting-the-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-18-p-1-gatsby-js-blog-starting-the-project-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-18-p-2-gatsby-js-blog-layout-and-pages-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-18-p2-gatsby-js-blog-layout-and-pages/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-18-p-2-gatsby-js-blog-layout-and-pages-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-18-p-3-gatsby-js-blog-list-and-posts-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-18-p3-gatsby-js-blog-list-and-posts/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-18-p-3-gatsby-js-blog-list-and-posts-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-21-p-4-gatsby-js-blog-featured-image-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-21-p4-gatsby-js-blog-featured-image/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-21-p-4-gatsby-js-blog-featured-image-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-22-p-5-gatsby-js-blog-seo-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-02-22-p5-gatsby-js-blog-seo/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-02-22-p-5-gatsby-js-blog-seo-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-01-the-best-full-stack-projects-2023-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-01-the-best-full-stack-projects-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-01-the-best-full-stack-projects-2023-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-1-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p1-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-1-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-2-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p2-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-2-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-3-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p3-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-3-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-4-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p4-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-4-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-5-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p5-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-5-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-6-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p6-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-6-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-7-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p7-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-7-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-8-react-native-navigation-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-06-23-p8-react-native-navigation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-06-23-p-8-react-native-navigation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-06-p-1-remix-games-app-tutorial-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-07-06-p1-remix-games-app-tutorial/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-06-p-1-remix-games-app-tutorial-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-07-p-2-remix-games-app-tutorial-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-07-07-p2-remix-games-app-tutorial/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-07-p-2-remix-games-app-tutorial-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-07-p-3-remix-games-app-tutorial-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-07-07-p3-remix-games-app-tutorial/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-07-p-3-remix-games-app-tutorial-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-09-p-4-remix-games-app-tutorial-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-07-09-p4-remix-games-app-tutorial/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-09-p-4-remix-games-app-tutorial-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-10-p-5-remix-games-app-tutorial-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2023-07-10-p5-remix-games-app-tutorial/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2023-07-10-p-5-remix-games-app-tutorial-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2024-01-27-ui-code-guidelines-index-mdx": () => import("./../../../src/templates/BlogPost.tsx?__contentFilePath=/opt/build/repo/src/content/2024-01-27-ui-code-guidelines/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-src-content-2024-01-27-ui-code-guidelines-index-mdx" */),
  "component---src-templates-tagged-posts-tsx": () => import("./../../../src/templates/TaggedPosts.tsx" /* webpackChunkName: "component---src-templates-tagged-posts-tsx" */)
}

